import { CcCookie, CkCookieTemplate } from '@interfaces/models/cookie';
import { AvailableCurrencies } from '@interfaces/models/currency';
import { SupportedLanguages } from '@interfaces/models/language';
import { Preferences } from '@interfaces/models/preferences';
import { RegionId, RegionName } from '@interfaces/models/region';
import { AvailableCountryISOCodes } from '@interfaces/models/country';
import { getLocaleByLanguage, getRegionNameById } from '@helpers/localization';
import Cookies from 'universal-cookie';

export default class PreferenceCookie {
  // In development environment you may not have this cookie set, so we need to mock it. On all other environments, ewok handles this for you
  static parseCookieCK(ckCookie: CkCookieTemplate = '6.en.USD'): Omit<Preferences, 'country'> {
    const [unparsedSiteId, language, currency] = ckCookie.split('.') as [
      string,
      SupportedLanguages,
      AvailableCurrencies,
    ];
    const locale = getLocaleByLanguage(language);
    const siteId = Number(unparsedSiteId) as RegionId;
    const regionName: RegionName = getRegionNameById(siteId);

    return {
      siteId,
      regionName,
      language,
      currency,
      locale,
    };
  }

  static parseCookieCC(cookieValue?: string, cookies?: Cookies): { country: AvailableCountryISOCodes } {
    const VCCOUNTRY = cookies.get('vc_country');
    // If default fallback US VC_CC we return the country in the vc_country cookie
    if (cookieValue === 'eyJDQyI6IlVTIiwiZGlzcGxheU5hbWUiOnt9fQ' && VCCOUNTRY) {
      const country = VCCOUNTRY;
      return { country };
    }

    const { CC: country } = PreferenceCookie.decode(cookieValue) ?? {
      CC: 'US',
    };
    return {
      country,
    };
  }

  /**
   * Encodes cookie value.
   *
   * @param data value to be encoded
   * @returns encoded cookie value
   */
  static encode(data: CcCookie): string {
    const search = { '+': '-', '/': '_' };
    const encoded: string = Buffer.from(JSON.stringify(data))
      .toString('base64')
      .replace(/\+|\//gi, (matched: string) => search[matched]);

    return encoded.replace(/=+$/, '');
  }

  /**
   * Decodes cookie value.
   *
   * @param data value to be decoded
   * @returns decoded cookie value
   */
  static decode(data: string): CcCookie {
    try {
      const search: {
        '-': string;
        _: string;
      } = { '-': '+', _: '/' };

      const encoded: string = data.replace(/-|_/gi, (matched) => search[matched]);
      const padding: string[] = new Array((encoded.length % 4) + 1);

      return JSON.parse(Buffer.from(encoded + padding.join('='), 'base64').toString());
    } catch (e) {
      // as a fallback we declare based on subdomain TODO
      return null;
    }
  }
}
